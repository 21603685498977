import React, {useEffect, useRef, useState} from 'react';
import { google, outlook, ics } from "calendar-link";
import { CalendarEvent } from 'calendar-link';
import AppleIcon from '../../svg/Apple';
import GoogleIcon from '../../svg/Google';
import OutlookIcon from '../../svg/Outlook';
import styles from './AddToCalendar.module.scss'

const AddToCalendar = () => {
  const btnRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(btnRef);

  const event: CalendarEvent = {
    title: 'Segundo añito de Camila 🥳',
    start: '2024-09-08 11:00:00',
    duration: [6.5, 'hour'],
    location: "Zoológico Los Coyotes",
  };

  const googleUrl = google(event);
  const outlookUrl = outlook(event);
  const icsUrl = ics(event);

  return (
    <div className={styles['atc-btn']} ref={btnRef}>
      <button
        className={`btn-blue fill w-100 ${isOpen ? styles['active'] : ''}`}
        onClick={() => setIsOpen(!isOpen)}>
        AGENDAR EN EL CALENDARIO
      </button>
      { isOpen && (
        <ul className={styles['links-wrapper']}>
          <li>
            <a href={icsUrl} target="_blank" rel="noreferrer" onClick={() => setIsOpen(!isOpen)}>
              <AppleIcon />
              Apple (iCal)
            </a>
          </li>
          <li>
            <a href={googleUrl} target="_blank" rel="noreferrer" onClick={() => setIsOpen(!isOpen)}>
              <GoogleIcon />
              Google
            </a>
          </li>
          <li>
            <a href={outlookUrl} target="_blank" rel="noreferrer" onClick={() => setIsOpen(!isOpen)}>
              <OutlookIcon />
              Outlook.com
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default AddToCalendar;