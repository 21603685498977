import React from 'react';
import styles from './Description.module.scss'

const Description = () => {
  return (
    <section id="description" className={`${styles['description-section']} bg-animated`} data-aos="flip-left">
      <div className="container">
        <p>Estos <b>dos años</b> han sido un <b>viaje increíble</b> lleno de sonrisas 😊, travesuras 😜 y descubrimientos 🌟. Desde sus primeras palabras hasta sus primeros pasos 🚶‍♀️ y sus risas contagiosas 😂, Cami ha traído una <b>felicidad infinita</b> a nuestras vidas. Cada día es una nueva aventura, y estamos emocionados por todo lo que está por venir. <br/><b>¡Gracias por ser parte de esta maravillosa experiencia con nosotros!</b> 🎉❤️.</p>
      </div>
      <div className={styles['bg-toy']}></div>
    </section>
  );
};

export default Description