import React from 'react';
import AddToCalendar from '../../utils/AddToCalendar/AddToCalendar';
import MapImg from '../../assets/img/map.jpg';
import styles from './Address.module.scss'

const Address = () => {
  return (
    <section className={styles['address-section']} id="address">
      <div className="container">
        <div className="title-cloud" data-aos="zoom-in-down">
          <h2>Dónde y Cuándo</h2>
        </div>

        <div className="card"  data-aos="fade-up">
          <div className="grid-1x2">
            <div>
              <img src={MapImg} alt="Zoólogico" />
            </div>
            <div className={styles['owl']}>
              <h5>Zoológico Los Coyotes 🐾 🐻 </h5>
              <p className="mb-0"><u>Palapa 2</u></p>
              <p className="mb-0"><u>Cuándo:</u> Domingo, 8 de septiembre del 2024.</p>
              <p className="mb-0"><u>Horario:</u> De 11:00 a 17:00 hrs.</p>
              <p className="mb-0"><u>Dirección</u>: Av. H. Escuela Naval Militar S/N, Coapa, Ex-Ejido de San Pablo Tepetlapa, Coyoacán, CDMX.</p>

              <a href="https://maps.app.goo.gl/BRK6h5N5WdVTSn2U9" target="_blank" rel="noreferrer" className="border-0 mt-3 w-100">
                <button className="btn-blue fill w-100 mt-2 mb-2">VER MAPA</button>
              </a>

              <AddToCalendar />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Address;