import { Image } from "react-grid-gallery";

export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [
  {
    src: "./photos/1.png",
    original: "./photos/1.png",
    width: 928,
    height: 1455,
  },
  {
    src: "./photos/2.png",
    original: "./photos/2.png",
    width: 478,
    height: 627,
  },
  {
    src: "./photos/3.png",
    original: "./photos/3.png",
    width: 933,
    height: 1548,
  },
  {
    src: "./photos/5.png",
    original: "./photos/5.png",
    width: 534,
    height: 988,
  },
  {
    src: "./photos/6.png",
    original: "./photos/6.png",
    width: 934,
    height: 1685,
  },
  {
    src: "./photos/7.png",
    original: "./photos/7.png",
    width: 934,
    height: 1791,
  },
  {
    src: "./photos/8.png",
    original: "./photos/8.png",
    width: 2007,
    height: 1503,
  },
  {
    src: "./photos/9.png",
    original: "./photos/9.png",
    width: 931,
    height: 1342,
  },
  {
    src: "./photos/10.png",
    original: "./photos/10.png",
    width: 927,
    height: 1692,
  },
  {
    src: "./photos/11.png",
    original: "./photos/11.png",
    width: 535,
    height: 920,
  },
  {
    src: "./photos/12.png",
    original: "./photos/12.png",
    width: 929,
    height: 1488,
  },
  {
    src: "./photos/13.png",
    original: "./photos/13.png",
    width: 934,
    height: 897,
  },
  {
    src: "./photos/14.png",
    original: "./photos/14.png",
    width: 928,
    height: 1463,
  },
  {
    src: "./photos/15.png",
    original: "./photos/15.png",
    width: 680,
    height: 680,
  },
  {
    src: "./photos/16.png",
    original: "./photos/16.png",
    width: 711,
    height: 1147,
  }
];