import React from 'react';
import styles from './Indications.module.scss'

const Indications = () => {
  return (
    <section className={styles['indications-section']}>
      <div className="container">
        <div className="title-cloud" data-aos="zoom-in-down">
          <h2>Indicaciones</h2>
        </div>

        <div className="grid-1x2">
          <div className={`card ${styles['bag']}`} data-aos="fade-up">
            <div id="indications">
              <p>🦊🌳 Para llegar en <b>carro</b> 🚗, solo toma <b>Av. Insurgentes Sur</b> desde el centro de la CDMX, luego sigue por <b>Av. del Imán y Av. H. Escuela Naval Militar</b>. Hay un <b>estacionamiento público</b> cerca 🚙, así que trata de llegar temprano para asegurar un lugar.</p>
              <p>Si prefieres el <b>transporte público</b> 🚇, toma la <b>Línea 2</b> del metro hasta <b>Taxqueña</b> y luego un taxi 🚕 o microbús hacia el zoológico. También puedes usar la <b>Línea 1</b> del <b>Metrobús</b> 🚌 hasta <b>El Caminero</b> y de ahí tomar un taxi 🚖 o microbús.</p>
              <p>Una vez en el zoológico 🏞️, ingresa por la entrada principal y sigue los senderos. La <b>Palapa 2</b> está al lado del <b>Skatepark</b> 🛹. Solo presta atención a los letreros 🪧 o pregunta al personal si necesitas ayuda.</p>
              <p>En el zoológico, podremos ver <b>animales</b> increíbles como coyotes 🐺, venados 🦌 y águilas 🦅. Hay <b>juegos infantiles</b> para los peques 🛝, senderos para pasear y relajarse 🌿 y <b>puestos de comida</b> donde puedes comprar snacks 🍿 y bebidas 🥤.</p>
              <p>Para estar cómodo durante el día, usa <b>ropa fresca y cómoda</b> 👕, <b>protector solar </b>🧴 y un <b>sombrero</b> 🧢. No olvides un buen par de zapatos 👟 para caminar.</p>
              <p>La entrada es <b>gratis</b> 🎟️.</p>
              <p><b>¡Nos vemos ahí para un día lleno de aventuras y diversión!</b> 🐒🌳🐾</p>
            </div>
          </div>
          <div className={`card ${styles['ball']}`} data-aos="fade-up">
            <div id="regulation">
            <p>¡Para que todos disfrutemos de una visita segura y divertida, por favor ten en cuenta las siguientes <b>reglas</b>!</p>
            <p><b>1. Mascotas no permitidas 🐶❌:</b> Para la seguridad de todos los animales.</p>
            <p><b>2. No se permiten balones o pelotas 🎾🚫:</b> Para evitar distracciones y asegurar la seguridad de todos.</p>
            <p><b>3. No alimentes ni molestes a los animales 🐒🍎🦉✋:</b> Los alimentos humanos pueden ser perjudiciales para ellos. Mantén una distancia segura.</p>
            <p><b>4. Prohibido consumir e introducir bebidas alcohólicas o drogas 🍺🚫:</b> Para mantener un entorno seguro.</p>
            <p><b>5. No se permiten fuegos pirotécnicos, confeti, globos o pistolas de agua 🎆🎈🚫:</b> Para evitar molestias a los animales.</p>
            <p><b>6. Mantente en los senderos señalados 🛤️🚶:</b> No ingreses a las áreas restringidas.</p>
            <p><b>7. Respeta las áreas de no fumar 🚭❌:</b> Para mantener un ambiente limpio.</p>
            <p><b>8. No arrojes basura 🚮🚫:</b> Ayúdanos a separar los residuos en orgánicos e inorgánicos.</p>
            <p><b>9. Vigila a los niños pequeños 👶👀:</b> Mantenlos siempre bajo supervisión.</p>
            <p><b>10. Fotografía sin flash 📸⚡:</b> Para no estresar a los animales.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Indications;