import React, { useEffect, useState } from 'react';
import MusicOnGif from '../../assets/icons/music-on.gif';
import styles from './Player.module.scss';

const EXTERNAL_AUDIO = require('../../assets/media/bluey.mp3');

const Player = () => {
  // eslint-disable-next-line
  const [audio, setAudio] = useState(new Audio(EXTERNAL_AUDIO))
	const [isPlay, setIsPlay] = useState(true);

  useEffect(() => {
    audio.loop = true;
    audio.play();
  }, [audio])

  const handleAudio = () => {
    if (!isPlay) {
      setIsPlay(true)

      audio.play();
    } else {
      setIsPlay(false)
      audio.pause();
    }
  }

  return (
    <>
      <button className={styles['audio-section']} onClick={handleAudio}>
        {isPlay ?
          <img src={MusicOnGif} alt="MusicOn" />
          :
          <i className="bi bi-play-fill"></i>
        }
      </button>
    </>
  );
}

export default Player;