import React from 'react';
import styles from './Itinerary.module.scss'
import WowImg from '../../assets/img/bingo-bluey-uuu.gif';
import Timeline from '../../utils/Timeline/Timeline';

const Itinerary = () => {
  return (
    <section className={styles['itinerary-section']} id="itinerary">
      <div className="container">
        <div className="title-cloud" data-aos="zoom-in-down">
          <h2>Itinerario</h2>
        </div>

        <div className="card" data-aos="fade-up">
          <div className="grid-1x2-1169">
            <div>
              <Timeline />
            </div>
            <div className={styles['img-wrapper']}>
              <img className={styles['img-section']} src={WowImg} alt="Wow"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Itinerary;