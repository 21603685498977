import React from 'react';
import AmazonImg from '../../assets/img/amazon.png';
import styles from './Presents.module.scss';

const Present = () => {
  return (
    <section id="presents" className={styles['presents-section']} data-aos="zoom-in">
      <div className="container">
        <h2 className="hello">Mesa de Regalos</h2>
        <p>Hemos preparado una <b>lista</b> con algunas ideas para <b>inspirarte</b>. </p>
        <p>Al elegir comprar a través de <b>Amazon</b>, tu regalo llegará directamente a las <b>manos de la festejada</b>.</p>
        <p>No hay nada que valoremos más que el <b>cariño</b> y la <b>consideración</b> detrás de cada <b>obsequio</b>. 🌟💜🎁</p>

        <a href="https://www.amazon.com.mx/registries/gl/guest-view/5VM7R67IOOGV" target="_blank" rel="noreferrer" className="border-0">
          <button className="btn-yellow fill mt-2 mb-2"><img src={AmazonImg} alt="Amazon" /></button>
        </a>
      </div>
    </section>
  );
};

export default Present;
