import React, {useState} from 'react';
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { images, CustomImage } from "./images";
import styles from './Gallery.module.scss';

const slides = images.map(({ original, width, height }) => ({
  src: original,
  width,
  height,
}));

const GalleryPhoto = () => {
  const [index, setIndex] = useState(-1);

  const handleClick = (index: number, item: CustomImage) => setIndex(index);

  return (
    <section id="gallery" className={styles['gallery-section']} data-aos="fade-right">
      <div className="container">
        <h2 className="hello">Momentos Especiales</h2>

        <Gallery
          images={images}
          onClick={handleClick}
          enableImageSelection={false}
        />
        <Lightbox
          slides={slides}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
        />
      </div>
    </section>
  );
};

export default GalleryPhoto;