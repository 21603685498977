import React from 'react';
import CountdownTimer from '../../utils/CountdownTimer/CountdownTimer';
import './Countdown.scss'

const Countdown = () => {
  const dateTimeAfterThreeDays = 'Sep 8, 2024 11:00:00';

  return (
    <section className="countdown-section" id="counter" data-aos="zoom-in">
      <CountdownTimer targetDate={dateTimeAfterThreeDays} />
    </section>
  );
};

export default Countdown
