import React from 'react';
import styles from './Confirm.module.scss'

const Name = () => {
  return (
    <section id="confirm" className={styles['confirm-section']} data-aos="fade-down">
      <div className="container">
        <h2 className="hello">Confirmación de asistencia</h2>

        <p className="mt-2">Por favor, avísanos si podrás <b>asistir</b> y si traerás <b>acompañantes</b>.</p>
        <p><b>¡Estamos emocionados de celebrar contigo!</b></p>

        <a href="https://api.whatsapp.com/send/?phone=5539238523&amp;text=Hola! Confirmo mi asistencia para el segundo cumpleaños de Cami 🥳" target="_blank" rel="noreferrer" className="border-0">
          <button className="btn-red fill mt-2 mb-2">
            <i className="bi bi-whatsapp"></i>
            CONFIRMAR A MAMÁ
          </button>
        </a>

        <a href="https://api.whatsapp.com/send/?phone=5580995029&amp;text=Hola! Confirmo mi asistencia para el segundo cumpleaños de Cami 🥳" target="_blank" rel="noreferrer" className="border-0">
          <button className="btn-red fill mt-2 mb-2">
            <i className="bi bi-whatsapp"></i>
            CONFIRMAR A PAPÁ
          </button>
        </a>
      </div>
    </section>
  );
};

export default Name
