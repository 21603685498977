import React from "react";

function OutlookIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 175">
      <path
        fill="#0364b8"
        d="M178.725 0H71.275A8.775 8.775 0 0062.5 8.775v9.975l60.563 18.75L187.5 18.75V8.775A8.775 8.775 0 00178.725 0z"
      ></path>
      <path
        fill="#0a2767"
        d="M197.813 96.281c.915-2.878 2.187-5.855 2.187-8.781-.002-1.485-.795-2.857-1.491-3.26l-68.434-38.99a9.37 9.37 0 00-9.244-.519 9.367 9.367 0 00-.906.512l-67.737 38.6-.025.013-.075.044A4.16 4.16 0 0050 87.5a70.682 70.682 0 002.188 8.781l71.825 52.532z"
      ></path>
      <path
        fill="#28a8ea"
        d="M150 18.75h-43.75L93.619 37.5l12.631 18.75L150 93.75h37.5v-37.5z"
      ></path>
      <path fill="#50d9ff" d="M150 18.75h37.5v37.5H150z"></path>
      <path
        fill="#0364b8"
        d="M150 93.75l-43.75-37.5H62.5v37.5l43.75 37.5 67.7 11.05z"
      ></path>
      <path
        fill="#0078d4"
        d="M106.25 56.25v37.5H150v-37.5zM150 93.75v37.5h37.5v-37.5zm-87.5-75h43.75v37.5H62.5z"
      ></path>
      <path fill="#064a8c" d="M62.5 93.75h43.75v37.5H62.5z"></path>
      <path
        fill="#0a2767"
        d="M126.188 145.113l-73.706-53.75 3.094-5.438 68.181 38.825a3.3 3.3 0 002.625-.075l68.331-38.937 3.1 5.431z"
        opacity="0.5"
      ></path>
      <path
        fill="#1490df"
        d="M197.919 91.106l-.088.05-.019.013-67.738 38.588a9.358 9.358 0 01-9.125.569l23.588 31.631 51.588 11.257v-.001a9.362 9.362 0 003.875-7.587V87.5a4.161 4.161 0 01-2.081 3.606z"
      ></path>
      <path
        d="M200 165.625v-4.613l-62.394-35.55-7.531 4.294a9.356 9.356 0 01-9.125.569l23.588 31.631 51.588 11.231v.025a9.362 9.362 0 003.875-7.588z"
        opacity="0.05"
      ></path>
      <path
        d="M199.688 168.019l-68.394-38.956-1.219.688a9.355 9.355 0 01-9.125.575l23.588 31.631 51.587 11.256v.001a9.38 9.38 0 003.562-5.187z"
        opacity="0.1"
      ></path>
      <path
        fill="#28a8ea"
        d="M51.455 90.721c-.733-.467-1.468-1.795-1.455-3.221v78.125A9.362 9.362 0 0059.375 175h131.25c1.395-.015 2.614-.366 3.813-.813.638-.258 1.252-.652 1.687-.974z"
      ></path>
      <path
        d="M112.5 141.669V39.581a8.356 8.356 0 00-8.331-8.331H62.687v46.6l-10.5 5.987-.031.012-.075.044A4.162 4.162 0 0050 87.5v.031-.031V150h54.169a8.356 8.356 0 008.331-8.331z"
        opacity="0.1"
      ></path>
      <path
        d="M106.25 147.919V45.831a8.356 8.356 0 00-8.331-8.331H62.687v40.35l-10.5 5.987-.031.012-.075.044A4.162 4.162 0 0050 87.5v.031-.031 68.75h47.919a8.356 8.356 0 008.331-8.331z"
        opacity="0.2"
      ></path>
      <path
        d="M106.25 135.419V45.831a8.356 8.356 0 00-8.331-8.331H62.687v40.35l-10.5 5.987-.031.012-.075.044A4.162 4.162 0 0050 87.5v.031-.031 56.25h47.919a8.356 8.356 0 008.331-8.331z"
        opacity="0.2"
      ></path>
      <path
        d="M100 135.419V45.831a8.356 8.356 0 00-8.331-8.331H62.687v40.35l-10.5 5.987-.031.012-.075.044A4.162 4.162 0 0050 87.5v.031-.031 56.25h41.669a8.356 8.356 0 008.331-8.331z"
        opacity="0.2"
      ></path>
      <path
        fill="#0078d4"
        d="M8.331 37.5h83.337A8.331 8.331 0 01100 45.831v83.338a8.331 8.331 0 01-8.331 8.331H8.331A8.331 8.331 0 010 129.169V45.831A8.331 8.331 0 018.331 37.5z"
      ></path>
      <path
        fill="#fff"
        d="M24.169 71.675a26.131 26.131 0 0110.263-11.337 31.031 31.031 0 0116.313-4.087 28.856 28.856 0 0115.081 3.875 25.875 25.875 0 019.988 10.831 34.981 34.981 0 013.5 15.938 36.881 36.881 0 01-3.606 16.662 26.494 26.494 0 01-10.281 11.213 30 30 0 01-15.656 3.981 29.556 29.556 0 01-15.425-3.919 26.275 26.275 0 01-10.112-10.85 34.119 34.119 0 01-3.544-15.744 37.844 37.844 0 013.481-16.563zm10.938 26.613a16.975 16.975 0 005.769 7.463 15.069 15.069 0 009.019 2.719 15.831 15.831 0 009.631-2.806 16.269 16.269 0 005.606-7.481 28.913 28.913 0 001.787-10.406 31.644 31.644 0 00-1.687-10.538 16.681 16.681 0 00-5.413-7.75 14.919 14.919 0 00-9.544-2.956 15.581 15.581 0 00-9.231 2.744 17.131 17.131 0 00-5.9 7.519 29.85 29.85 0 00-.044 21.5z"
      ></path>
    </svg>
  );
}

export default OutlookIcon;