import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import {
  faBreadSlice, faCakeCandles, faHourglassEnd, faWandSparkles
} from '@fortawesome/free-solid-svg-icons';
import 'react-vertical-timeline-component/style.min.css';
import ByeImg from '../../assets/icons/bye.png';
import CakeImg from '../../assets/icons/cake.png';
import PartyImg from '../../assets/icons/party.png';
import SnacksImg from '../../assets/icons/snacks.png';
import './Timeline.scss';


const Timeline = () => {
  const breadIcon = {
    icon: <FontAwesomeIcon icon={faBreadSlice} />,
    iconStyle: { background: '#F09AAE', color: '#FFFFFF' },
  };

  const cakeIcon = {
    icon: <FontAwesomeIcon icon={faCakeCandles} />,
    iconStyle: { background: '#F09AAE', color: '#FFFFFF' },
  };

  const endIcon = {
    icon: <FontAwesomeIcon icon={faHourglassEnd} />,
    iconStyle: { background: '#F09AAE', color: '#FFFFFF' },
  };

  const sparklesIcon = {
    icon: <FontAwesomeIcon icon={faWandSparkles} />,
    iconStyle: { background: '#F09AAE', color: '#FFFFFF' },
  };

  const timeline = [
    {
      icon: sparklesIcon,
      date: '11:00 hrs.',
      img: PartyImg,
      title: '¡Fiesta!'
    },
    {
      icon: breadIcon,
      date: '13:00 hrs.',
      img: SnacksImg,
      title: 'Bocadillos'
    },
    {
      icon: cakeIcon,
      date: '14:30 hrs.',
      img: CakeImg,
      title: 'Pastel'
    },
    {
      icon: endIcon,
      date: '17:00 hrs.',
      img: ByeImg,
      title: '¡Gracias!'
    }
  ];

  return (
    <VerticalTimeline>
      {timeline.map((t, i) => {
        const contentStyle = { background: '#DCD2FB', color: '#434365' };
        const arrowStyle = { borderRight: '10px solid  #DCD2FB' } ;

        return (
          <VerticalTimelineElement
            key={i}
            className="vertical-timeline-element--i"
            contentStyle={contentStyle}
            contentArrowStyle={arrowStyle}
            date={t.date}
            {...t.icon}
          >
            {t.title ? (
              <React.Fragment>
                <img src={t.img} alt={t.title} />
                <h4 className="vertical-timeline-element-title">{t.title}</h4>
              </React.Fragment>
            ) : undefined}
          </VerticalTimelineElement>
        );
      })}
    </VerticalTimeline>
  )
};

export default Timeline;
