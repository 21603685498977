import React, { useCallback, useEffect, useState } from 'react';
import styles from './Home.module.scss'

const Home = () => {
  const [y, setY] = useState(window.scrollY);
  const [isScrollingDown, setScrollingDown] = useState(false);

  const handleNavigation = useCallback(
    (e:any) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        setScrollingDown(false);
      } else if (y < window.scrollY) {
        setScrollingDown(true);
      }
      setY(window.scrollY);
    }, [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <section className={styles['home-section']} id="home" data-aos="fade-down">
      <h1 className="heyam">Camila</h1>
      <h3>Mi segundo añito</h3>
      <div className={`${styles['animation']} ${isScrollingDown ? styles['interaction'] : ''}`}></div>
    </section>
  );
};

export default Home
