import React, {useCallback, useEffect, useState} from 'react';
import styles from './Footer.module.scss';


const Footer = () => {
  const [y, setY] = useState(window.scrollY);
  const [isScrollingUp, setScrollingUp] = useState(false);

  const handleNavigation = useCallback(
    (e:any) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        setScrollingUp(true);
      } else if (y < window.scrollY) {
        setScrollingUp(false);
      }
      setY(window.scrollY);
    }, [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <footer className={styles['footer-wrapper']}>
      <h2>¡Te esperamos!</h2>
      <div className={`${styles['animation']} ${isScrollingUp ? styles['interaction'] : ''}`}></div>
    </footer>
  )
};

export default Footer;
